import RouteName from "./models/ApiRouteInteface";

const baseApiRoute = process.env.REACT_APP_API_BASE_LINK;

const apiRoutes: RouteName = {
    // === auth ===
    user: baseApiRoute + '/v1/auth/user',
    login: baseApiRoute + '/v1/auth/sign-in',
    checkAndRecoverEmail: baseApiRoute + '/v1/auth/recover-account/1',
    recoverAccount: baseApiRoute + '/v1/auth/recover-account/2',
    checkToken: baseApiRoute + '/v1/auth/token/',
    registerTeacher: baseApiRoute + '/v1/auth/sign-up/teacher',
    registerParent: baseApiRoute + '/v1/auth/sign-up/parent',
    registerStudent: baseApiRoute + '/v1/auth/sign-up/child-student',
    logout: baseApiRoute + '/v1/auth/sign-out',
    verifyToken: baseApiRoute + '/v1/auth/verify/TOKEN',
    // === lesson ===
    baseLesson: baseApiRoute + '/v1/lesson/',
    // === quiz ===
    baseQuiz: baseApiRoute + '/v1/quiz/',
    verifyQuiz: baseApiRoute + '/v1/quiz/SLUG/verify',
    // === course ===
    createCourse: baseApiRoute + '/v1/course/',
    assignCourseToRole: baseApiRoute + '/v1/course/COURSE_SLUG/assign-all-teachers',
    listCourseDetails: baseApiRoute + '/v1/course/',
    listCoursesAll: baseApiRoute + '/v1/course/all',
    deleteCourse: baseApiRoute + '/v1/course/SLUG',
    searchCoursesAll: baseApiRoute + '/v1/course/search?name=',
    coursesSearchUsers: baseApiRoute + '/v1/course/search-for/SLUG/users',
    assignCourses: baseApiRoute + '/v1/course/COURSE_SLUG/assign/USER_SLUG',
    unassignCourses: baseApiRoute + '/v1/course/COURSE_SLUG/unassign/USER_SLUG',
    listCoursesMine: baseApiRoute + '/v1/course/mine',
    listCourseProgress: baseApiRoute + '/v1/course/mine/COURSE_SLUG/progress',
    courseProgressNext: baseApiRoute + '/v1/course/mine/COURSE_SLUG/progress/next',
    // === lives ===
    rootLives: baseApiRoute + '/v1/lives/',
    listMyLives: baseApiRoute + '/v1/lives/mine',
    joinLive: baseApiRoute + '/v1/lives/SLUG/join',
    stopLive: baseApiRoute + '/v1/lives/SLUG/stop',
    saveTime: baseApiRoute + '/v1/lives/SLUG/save-time',
    generateAward: baseApiRoute + '/v1/lives/generate-award/',
    // === users ===
    usersAll: baseApiRoute + '/v1/user',
    toggleActivation: baseApiRoute + '/v1/user/SLUG/activation/toggle',
    usersSearchAll: baseApiRoute + '/v1/user/search',
    usersSearchCourses: baseApiRoute + '/v1/user/search-for/SLUG/courses',
    exportAllUsers: baseApiRoute + '/v1/user/export',
    // === teachers ===
    teacherDetails: baseApiRoute + '/v1/teachers/',
    teacherStudentCourses: baseApiRoute + '/v1/teachers/SLUG/student-courses',
    // === permissions ===
    permissionsAll: baseApiRoute + '/v1/permissions',
    // === health ===
    health: baseApiRoute + '/v1/utils/health',
    // === awards ===
    rootAwards: baseApiRoute + '/v1/awards/',
    myAwards: baseApiRoute + '/v1/awards/mine',
    // === projects ===
    rootProjects: baseApiRoute + '/v1/projects/',
    unanswered: baseApiRoute + '/v1/projects/unread',
    myProjects: baseApiRoute + '/v1/projects/mine',
    acceptProject: baseApiRoute + '/v1/projects/SLUG/accept',
    rejectProject: baseApiRoute + '/v1/projects/SLUG/reject',
    // === log ===
    logPath: baseApiRoute + '/v1/activity/log',
};

export default apiRoutes;