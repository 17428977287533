import React, {FormEvent, useState} from "react";
import RecoveryEmailService from "../functionals/service/RecoveryEmailService";
import LoadingText from "../../../commons/component/loading/views/LoadingText";
import {Toaster} from "react-hot-toast";

export default function PasswordRecoveryStart() {

    const [email, setEmail] = useState('');
    const [componentLoading, setComponentLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    function recoveryEmail(event: FormEvent) {
        event.preventDefault();
        setComponentLoading(true);

        new RecoveryEmailService().checkAndRecoverEmail(email).then((response) => {
            setSuccess(response.message);
            setError(null);
        }).catch((err) => {
            setSuccess(null);
            setError(err.response.data.message);
        }).finally(() => setComponentLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"h-screen w-screen flex justify-center items-center bg-background-color"}>
                <div className={"max-w-1/2 w-[400px] p-10 bg-white rounded-xl shadow-lg m-8"}>
                    <h1 className={"pb-4 text-3xl text-center"}>Recupereaza-ti contul</h1>
                    <form onSubmit={recoveryEmail} className={"flex justify-center items-align flex-col"}>
                        <input type="text" placeholder="Email"
                               required={true}
                               onChange={(e) => setEmail(e.target.value)}
                               className="my-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                        <div>
                            {
                                error == null ?
                                    <p className={"text-green-500 font-bold text-center text-lg"}>{success}</p>
                                    : <p className={"text-error font-bold text-center text-lg"}>{error}</p>
                            }
                        </div>
                        <button type="submit"
                                disabled={componentLoading}
                                className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"}>
                            {!componentLoading ? "Recupereaza cont" : <LoadingText color={"#fff"}/>}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}