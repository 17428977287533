import React, {useEffect, useState} from "react";
import ValidateProjectService from "../service/ValidateProjectService";
import {Toaster} from "react-hot-toast";
import ProjectItem from "../iterables/ProjectItem";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import CompleteProject from "../models/CompleteProject";
import BackButton from "../../../../../../../commons/component/BackButton";
import DashboardAdminRouter from "../../../../router/DashboardAdminRouter";
import ValidateProjectRouter from "../router/ValidateProjectRouter";

export default function ViewProjectHistory() {

    const [projects, setProjects] = useState<CompleteProject[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const createProjectService = new ValidateProjectService();

    useEffect(() => {
        setIsLoading(true);
        createProjectService.getHistoryProjects()
            .then(response => setProjects(response))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <div className={"px-3 md:px-8 w-full pb-6"}>
            <div><Toaster/></div>
            <Breadcrumbs paths={["Proiecte", "Istoric proiecte"]}/>
            <div className="flex items-center gap-3 text-xl md:text-4xl font-bold">
                <BackButton slug={ValidateProjectRouter.projectHistory} isFull={false} isSkinny={true}/>
                <p>Istoric proiecte:</p>
            </div>
            <div className="flex flex-col gap-2 w-full mt-5">
                {isLoading ?
                    <LoadingPlaceholder/> : projects.length === 0 ?
                        <p>Nu ai nicio implementare</p> : projects.map((project, index) => (
                            <div key={index}>
                                <ProjectItem project={project}
                                             accept={null} reject={null}
                                             isLoading={false}/>
                            </div>
                        ))}
            </div>
        </div>
    );
}