import React, {useEffect, useState} from "react";
import ValidateProjectService from "../service/ValidateProjectService";
import {toast, Toaster} from "react-hot-toast";
import ProjectItem from "../iterables/ProjectItem";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import CompleteProject from "../models/CompleteProject";
import BackButton from "../../../../../../../commons/component/BackButton";
import DashboardAdminRouter from "../../../../router/DashboardAdminRouter";
import {Link, useLocation} from "react-router-dom";
import ValidateProjectRouter from "../router/ValidateProjectRouter";

export default function ListUnapprovedProjects() {

    const [projects, setProjects] = useState<CompleteProject[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [componentLoading, setComponentLoading] = useState<boolean>(false);
    const createProjectService = new ValidateProjectService();
    const location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        createProjectService.getUnapprovedProjects()
            .then(response => setProjects(response))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    }, []);

    const approveProject = (slug: string) => {
        setComponentLoading(true);
        createProjectService.acceptProject(slug)
            .then((_) => {
                setProjects(projects.filter(project => project.slug !== slug));
                toast.success("Proiect aprobat!");
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setComponentLoading(false));
    }


    const rejectProject = (slug: string, rejectReason: string | null) => {
        setComponentLoading(true);
        createProjectService.rejectProject(slug, rejectReason)
            .then((_) => {
                setProjects(projects.filter(project => project.slug !== slug));
                toast.success("Proiect respins!");
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setComponentLoading(false));
    }

    return (
        <div className={"px-3 md:px-8 w-full pb-6"}>
            <div><Toaster/></div>
            <Breadcrumbs paths={["Proiecte", "Proiectele neaprobate"]}/>
            <div className="flex justify-between items-center gap-3 text-xl md:text-4xl font-bold">
                <div className={"flex-row flex gap-2"}>
                    <BackButton slug={DashboardAdminRouter.projects} isFull={false} isSkinny={true}/>
                    <p>Proiecte neaprobate:</p>
                </div>
                <Link className={"p-3 text-sm bg-accent-main-500 text-white rounded-lg"}
                      to={location.pathname + ValidateProjectRouter.projectHistory}>
                    Catre istoric proiecte
                </Link>
            </div>
            <div className="flex flex-col gap-2 w-full mt-5">
                {isLoading ?
                    <LoadingPlaceholder/> : projects.length === 0 ?
                        <p>Nu ai nicio implementare</p> : projects.map((project, index) => (
                            <div key={index}>
                                <ProjectItem project={project}
                                             accept={approveProject} reject={rejectProject}
                                             isLoading={componentLoading}/>
                            </div>
                        ))}
            </div>
        </div>
    );
}