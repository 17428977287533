import axios, {AxiosResponse} from "axios";
import apiRoutes from "../../../../../../../commons/environment/EnvironmentRoutes";
import GeneralResponse from "../../../../../../../commons/environment/models/GeneralResponse";
import CompleteProject from "../models/CompleteProject";

export default class ValidateProjectService {
    async getUnapprovedProjects(): Promise<CompleteProject[]> {
        const response: AxiosResponse<CompleteProject[]> = await axios.get<CompleteProject[]>(apiRoutes.unanswered, {withCredentials: true});
        return response.data;
    }

    async getHistoryProjects(): Promise<CompleteProject[]> {
        const response: AxiosResponse<CompleteProject[]> = await axios.get<CompleteProject[]>(apiRoutes.rootProjects, {withCredentials: true});
        return response.data;
    }


    async acceptProject(slug: string): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post<GeneralResponse<string>>(apiRoutes.acceptProject.replace("SLUG", slug), {}, {withCredentials: true});
        return response.data;
    }

    async rejectProject(slug: string, rejectReason: string | null): Promise<GeneralResponse<string>> {
        const response: AxiosResponse<GeneralResponse<string>> = await axios.post<GeneralResponse<string>>(apiRoutes.rejectProject.replace("SLUG", slug), {rejectReason: rejectReason}, {withCredentials: true});
        return response.data;
    }
}