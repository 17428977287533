import React, {useEffect, useState} from "react";
import Course, {
    defaultCourse
} from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Course";
import UserCoursesService from "../service/UserCoursesService";
import {useNavigate, useParams} from "react-router-dom";
import LoadingPlaceholder from "../../../../../../commons/component/loading/views/LoadingPlaceholder";
import UserCourseProgress from "../models/UserCourseProgress";
import LessonsService from "../service/LessonsService";
import Lesson from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Lesson";
import ResourceType from "../models/ResourceType";
import {GiTeacher} from "react-icons/gi";
import {FaClipboardQuestion} from "react-icons/fa6";
import ApplicationRoutes from "../../../../../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../../../router/DashboardUserRouter";
import LoadingText from "../../../../../../commons/component/loading/views/LoadingText";
import {toast, Toaster} from "react-hot-toast";
import Quiz from "../../../../../dashboardAdmin/functionals/components/coursesAdmin/functionals/models/models/Quiz";
import QuizzesService from "../service/QuizzesService";
import ReactConfetti from "react-confetti";
import QuestionCard from "../iterables/QuestionCard";
import BackButton from "../../../../../../commons/component/BackButton";
import ReactPlayer from "react-player";

export default function ViewCourse() {

    const [course, setCourse] = useState<Course>(defaultCourse);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const userCourseService = new UserCoursesService();
    const lessonService = new LessonsService();
    const quizzesService = new QuizzesService();
    const {courseSlug} = useParams<{ courseSlug: string }>()
    const navigate = useNavigate();

    const [isLoadingResource, setIsLoadingResource] = useState<boolean>(false);
    const [isLoadingNextResource, setIsLoadingNextResource] = useState<boolean>(false);
    const [currentResource, setCurrentResource] = useState<UserCourseProgress | undefined>(undefined);
    const [currentLesson, setCurrentLesson] = useState<Lesson | undefined>(undefined);
    const [currentQuiz, setCurrentQuiz] = useState<Quiz | undefined>(undefined);
    const [hasCorectlyFinishedTheQuiz, setHasCorectlyFinishedTheQuiz] = useState<boolean>(true);
    const [answersForCurrentQuiz, setAnswersForCurrentCuiz] = useState<{
        question: string,
        response: number
    }[]>([]);

    const [isVideoFinished, setIsVideoFinished] = useState<boolean>(false);

    useEffect(() => {
        if (courseSlug === undefined)
            return;

        setIsLoading(true);
        setIsLoadingResource(true);
        userCourseService.getCourse(courseSlug)
            .then((response) => setCourse(response))
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    navigate(ApplicationRoutes.dashboard + DashboardUserRouter.courses, {replace: true});
                    return;
                }
            })
            .finally(() => setIsLoading(false));

        userCourseService.getCourseProgress(courseSlug)
            .then(response => {
                setCurrentResource(response);
            })
            .catch(_ => console.log())
            .finally(() => setIsLoadingResource(false));
    }, [courseSlug]);

    useEffect(() => {
        if (currentResource === undefined || currentResource.current.lastResourceUsed === "")
            return;

        if (currentResource.current.lastResourceType === ResourceType.LESSON) {
            setHasCorectlyFinishedTheQuiz(true);
            setCurrentQuiz(undefined);
            setIsVideoFinished(false);
            lessonService.getLesson(currentResource.current.lastResourceUsed)
                .then(response => {
                    setIsVideoFinished(response.linkToResources === null);
                    setCurrentLesson(response);
                })
                .catch(_ => console.log());
        } else {
            setIsVideoFinished(true);
            setHasCorectlyFinishedTheQuiz(false);
            setCurrentLesson(undefined);
            quizzesService.getQuiz(currentResource.current.lastResourceUsed)
                .then(response => setCurrentQuiz(response))
                .catch(_ => console.log());
        }
    }, [currentResource]);

    const next = () => {
        if (courseSlug === undefined)
            return;

        setIsLoadingNextResource(true);
        userCourseService.nextResource(courseSlug)
            .then((response) => setCurrentResource(response))
            .catch((_) => toast.error("Eroare la avansare. Incearca din nou!"))
            .finally(() => setIsLoadingNextResource(false));
    }

    const solveQuiz = () => {
        if (currentQuiz === undefined)
            return;

        userCourseService.verifyResponses(currentQuiz.slug, answersForCurrentQuiz).then((response) => {
            if (response.success) {
                setAnswersForCurrentCuiz([]);
                toast.success("Felicitari, ai raspuns corect");
                setHasCorectlyFinishedTheQuiz(true);
            } else {
                toast.error("Nu ai rezolvat corect cel putin 60%. Revino asupra raspunsurilor tale!")
            }
        }).catch((_) => toast.error("Am intampinat o eroare la verificare"));
    }

    const selectResponse = (i: number, response: number) => {
        if (currentQuiz === undefined)
            return;

        const question = currentQuiz.questionsList[i].slug;
        setAnswersForCurrentCuiz(prevAnswers =>
            prevAnswers.some(answer => answer.question === question)
                ? prevAnswers.map(answer =>
                    answer.question === question ? {...answer, response} : answer
                )
                : [...prevAnswers, {question, response}]
        );
    }

    const navigateToResource = (resourceSlug: string) => {
        if (currentResource?.finished ?? false) {
            let currentToBe = (currentResource?.timeline ?? []).find((v) => v.lastResourceUsed = resourceSlug);
            setCurrentResource((prev) => ({...prev, current: currentToBe}) as UserCourseProgress)
        }
    }

    return (
        <div className={"flex gap-2 w-full flex-col md:flex-row"}>
            <div><Toaster/></div>
            <div className={"h-fit md:h-full items-center justify-center w-full md:w-64 p-5"}>
                <BackButton slug={courseSlug ?? ''} isFull={true}/>
                <div className={"h-fit overflow-y-auto bg-gray-50 w-full rounded-lg shadow-lg px-3 py-4 mt-3"}>
                    <img src={course.courseImage} className={"w-full h-48 object-cover rounded-lg mb-3"}/>
                    <h1 className={"text-2xl text-center font-semibold pb-4"}>{course.courseName}</h1>
                    {
                        isLoadingResource ?
                            <LoadingPlaceholder/> :
                            currentResource === undefined ?
                                <></> :
                                <>
                                    <div className="w-full bg-gray-200 rounded-full h-1.5">
                                        <div className="bg-accent-main-600 h-1.5 rounded-full"
                                             style={{"width": (currentResource!.finished ? "100" : currentResource!.timeline.map(e => e.lastResourceUsed).indexOf(currentResource!.current.lastResourceUsed) * 100 / currentResource!.timeline.length) + "%"}}></div>
                                    </div>
                                    <p className={"text-center mb-4"}>
                                        {
                                            (currentResource.finished ? currentResource!.timeline.length : currentResource!.timeline.map(e => e.lastResourceUsed).indexOf(currentResource!.current.lastResourceUsed)) + " / " + currentResource!.timeline.length
                                        }
                                    </p>
                                </>
                    }
                    <p className={"text-gray-600 my-3"}>{course.courseDescription.replace("href=\" https", "href=\"https")}</p>
                    <ul className={"space-y-2 font-medium"}>
                        {
                            isLoadingResource ?
                                <LoadingPlaceholder/> :
                                currentResource === undefined ?
                                    <></> :
                                    currentResource!.timeline.map((option, i) =>
                                        <li key={i} onClick={() => navigateToResource(option.lastResourceUsed)}>
                                            <div
                                                className={"flex items-center p-2 text-accent-main-700 rounded-lg group " + (currentResource!.finished ? "cursor-pointer" : currentResource!.current.lastResourceUsed === option.lastResourceUsed ? "bg-accent-main-100" : "")}>
                                                {
                                                    option.lastResourceType === ResourceType.LESSON ?
                                                        <GiTeacher/> :
                                                        <FaClipboardQuestion/>
                                                }
                                                <span className="ms-3">{option.name}</span>
                                            </div>
                                        </li>
                                    )
                        }
                    </ul>
                </div>
            </div>
            {
                currentResource && currentResource.current.lastResourceUsed === "" && currentResource.finished ?
                    <div className={"flex-1 w-full h-48 mr-8"}>
                        <div
                            className={"text-4xl text-center font-semibold py-5 px-10 md:mt-5 mb-5 bg-white rounded-lg shadow-lg mx-3 md:mx-0"}>
                            Felicitari!
                            Ai terminat acest curs!
                        </div>
                        <ReactConfetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            recycle={false}
                            numberOfPieces={500}
                        />
                    </div>
                    :
                    <div className={"flex-1 w-full h-full"}>
                        {
                            isLoading ?
                                <LoadingPlaceholder/> :
                                <div className={"w-full pl-4 pr-8"}>
                                    {
                                        isLoadingResource ?
                                            <LoadingPlaceholder/> :
                                            <div
                                                className={"py-5 px-10 mt-5 bg-white rounded-lg shadow-lg"}>
                                                <p className={"text-xl font-bold mb-5"}>
                                                    {
                                                        currentLesson === undefined ?
                                                            (
                                                                currentQuiz === undefined ?
                                                                    "Se incarca..." :
                                                                    currentQuiz.quizTitle
                                                            ) :
                                                            currentLesson.lessonName
                                                    }
                                                </p>
                                                {
                                                    currentLesson === undefined ?
                                                        currentQuiz === undefined ?
                                                            <LoadingPlaceholder/> :
                                                            <div className={"flex items-end flex-col"}>
                                                                {
                                                                    currentQuiz.questionsList.map((question, i) =>
                                                                        <QuestionCard key={i}
                                                                                      question={question}
                                                                                      canEdit={!hasCorectlyFinishedTheQuiz}
                                                                                      index={i}
                                                                                      selectResponse={(value) => selectResponse(i, value)}
                                                                        />)
                                                                }
                                                                <button
                                                                    disabled={hasCorectlyFinishedTheQuiz}
                                                                    onClick={solveQuiz}
                                                                    className={"disabled:bg-accent-main-200 text-white bg-accent-main-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit"}>
                                                                    Verifica raspunsurile
                                                                </button>
                                                            </div> :
                                                        currentLesson.linkToResources === null ? <></> :
                                                            <div className={"w-full h-full bg-black"}>
                                                                <ReactPlayer
                                                                    width={"100%"}
                                                                    height={"500px"}
                                                                    playing={true}
                                                                    url={currentLesson.linkToResources}
                                                                    onEnded={() => setIsVideoFinished(true)}
                                                                />
                                                            </div>
                                                }
                                            </div>
                                    }
                                    {
                                        currentLesson === undefined ?
                                            <></> :
                                            <div
                                                className={"py-5 px-10 my-5 bg-white rounded-lg shadow-lg text-gray-400"}
                                                dangerouslySetInnerHTML={{__html: currentLesson.lessonDescription.replace("href=\" https", "href=\"https")}}>
                                            </div>
                                    }
                                    {
                                        currentResource === undefined || currentResource.finished ?
                                            <></> :
                                            isVideoFinished &&
                                            <div className={"justify-center flex items-center m-8"}>
                                                <button
                                                    disabled={!isLoadingNextResource && !hasCorectlyFinishedTheQuiz}
                                                    onClick={next}
                                                    className={"disabled:bg-accent-main-200 text-white bg-accent-main-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-fit"}>
                                                    {isLoadingNextResource ?
                                                        <LoadingText
                                                            color={"#fff"}/> : hasCorectlyFinishedTheQuiz ? "Urmatorul pas" : "Finalizaza acest quiz"}
                                                </button>
                                            </div>

                                    }
                                </div>
                        }
                    </div>
            }
        </div>
    );

}